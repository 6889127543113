import React, {
  useState, useEffect, useContext, useRef
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, Input, Button } from '@flogistix/flo-ui';
import { IoRemoveCircle } from 'react-icons/io5';

import Verification from '../../../../../classes/verification';
import { getAllInstruments } from '../../../../../dexie/operations';
import SurveyInstrument from '../../../../../classes/survey-instrument';
import { InspectionContext } from '../../../../../context/InspectionContext';
import AirMethaneFile, { UploadFile, PreviewFile } from '../../../../../classes/airmethane-file';
import BaseDropzone from '../../../../../components/FileDropzone/BaseDropzone/BaseDropzone';
import './Verifications.scss';

export interface IVerification {
  verificationId?: string | null;
  altitude?: number | undefined;
  surveyInstrument?: SurveyInstrument;
  file?: AirMethaneFile;
  pendingFiles?: UploadFile[];
}

const VerificationCard = ({
  verification,
  index,
  deleteVerification
}: {
  verification: Verification,
  index: number,
  deleteVerification: () => void
}) => {
  const {
    inspection, token, setCompletedSectionsOnChange, fileOptions, hasFileId
  } = useContext(InspectionContext);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const [verificationFile, setVerificationFile] = useState<PreviewFile | null>(verification.file ?? null);
  const [verificationInstrument, setVerificationInstrument] = useState(verification.surveyInstrument ?? null);
  const [verificationAltitude, setVerificationAltitude] = useState(verification.altitude ?? undefined);
  const [searchInstrument, setSearchInstrument] = useState('');
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [instrumentOptions, setInstrumentOptions] = useState<{ label: string, value: string }[]>([]);

  const handleInstrumentChange = (selectedOption: { value: string, label: string }) => {
    const instrumentId = selectedOption.value;
    if (instrumentId !== verification.surveyInstrument?.id) {
      verification.patchVerification(token, inspection!.flogistixId!, inspection!, {
        ...verification,
        surveyInstrument: new SurveyInstrument(instrumentId)
      } as Verification).then(() => {
        setVerificationInstrument(new SurveyInstrument(instrumentId));
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleAltitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const altitude = e.target.value ? parseInt(e.target.value, 10) : undefined;
    if (altitude !== verification.altitude) {
      verification.patchVerification(token, inspection!.flogistixId!, inspection!, {
        ...verification,
        altitude
      } as Verification).then(() => {
        setVerificationAltitude(altitude);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    const files = event.type === 'drop'
      ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files
      : (event as React.ChangeEvent<HTMLInputElement>).target.files;
    const file = files?.[0];
    if (!file) return;

    const fileObj: UploadFile = {
      id: uuidv4(),
      file,
      displayName: file.name
    };

    verification.patchVerification(token, inspection!.flogistixId!, inspection!, {
      ...verification,
      pendingFiles: [fileObj]
    } as unknown as Verification).then(() => {
      const blob = URL.createObjectURL(fileObj.file);
      setVerificationFile({
        id: fileObj.id,
        previewUrl: blob,
        fileName: fileObj.displayName,
        fileType: fileObj.file.type
      } as PreviewFile);

      setCompletedSectionsOnChange();
    });
  };

  const handleVerificationAttachment = async (fileId: string | number) => {
    const selectedFile = fileOptions.find((file: { value: string | number; }) => file.value === fileId);

    if (selectedFile) {
      try {
        const newFile = new AirMethaneFile(selectedFile.value as string, selectedFile.value);
        verification.updateFileValue(newFile);

        const updatedVerification = {
          ...verification,
          file: newFile,
          pendingFiles: []
        };

        await verification.patchVerification(
          token,
          inspection!.flogistixId!,
          inspection!,
          updatedVerification as unknown as Verification
        );

        setVerificationFile({
          id: selectedFile.value as string,
          previewUrl: selectedFile.label,
          fileName: selectedFile.label,
          fileType: ''
        } as PreviewFile);

        setCompletedSectionsOnChange();
      } catch (error) {
        console.error('Error updating verification:', error);
      }
    }
  };

  const removeVerificationImage = (fileId: string | undefined) => {
    if (!fileId) return;

    const fileToRemove = new AirMethaneFile(fileId);

    verification.removeFile(
      token,
      inspection!.flogistixId!,
      fileToRemove,
      0,
      inspection!.id!
    ).then(() => {
      setVerificationFile({} as PreviewFile);
      setCompletedSectionsOnChange();
    });
  };

  const detachVerificationImage = (fileId: string | undefined) => {
    if (!fileId) return;

    verification.detachFile(
      token,
      inspection!.flogistixId!,
      inspection!.id!
    ).then(() => {
      setVerificationFile({} as PreviewFile);
      setCompletedSectionsOnChange();
    });
  };

  const handleRemoveClick = () => {
    setShowDeleteButton(true);
  };

  const handleDeleteClick = () => {
    deleteVerification();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteButtonRef.current
        && !deleteButtonRef.current.contains(event.target as Node)
      ) {
        setShowDeleteButton(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getInstrumentOptions = async () => {
      const instruments = await getAllInstruments();
      const options = instruments.map((instrument) => ({
        label: instrument.name,
        value: instrument.instrumentId
      }));
      setInstrumentOptions(options);
    };
    getInstrumentOptions();
  }, []);

  return (
    <div className="verification-card-container">
      <div ref={deleteButtonRef}>
        {
          index !== 0 && (
            showDeleteButton ? (
              <Button
                variation="red-outline"
                className="delete-button-container"
                size="medium"
                type="button"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            ) : (
              <button
                className="icon-button"
                type="button"
                onClick={handleRemoveClick}
                aria-label="Remove"
              >
                <IoRemoveCircle className="remove-icon" size={24} />
              </button>
            )
          )
        }
      </div>

      <div className="card">
        <section>
          <h5 className="card-header">Daily verification</h5>
          <p className="sub-label">Files should be in. jpg, .png, or .heic</p>
          <BaseDropzone
            onFileChange={handleFileSelection}
            acceptedTypes=".png, .jpg, .jpeg, .heic, .mp4, .mov, video, video/mp4"
            removeHandler={removeVerificationImage}
            detachHandler={detachVerificationImage}
            showRemoveButton={!!verificationFile?.id}
            fileId={verificationFile?.id}
            selectOptions={fileOptions.length > 0 ? fileOptions : undefined}
            onSelectChange={handleVerificationAttachment}
            deleteFileDisabled={hasFileId(verificationFile?.id as string)}
          />
          <label className="label">Instrument</label>
          {
            instrumentOptions.length > 0 ? (
              <Dropdown
                id="instrument-dropdown"
                value={instrumentOptions.find((option) => option.value === verificationInstrument?.id)?.label || ''}
                onSelect={(option) => handleInstrumentChange(option as { value: string, label: string })}
                options={instrumentOptions}
                onSearchChange={(value) => setSearchInstrument(value)}
                className="input__dropdown"
                searchValue={searchInstrument}
                placeholder="Select Instrument"
                fixedWidth="100%"
              />
            ) : <p>Instruments loading...</p>
          }
          <label className="label">Altitude</label>
          <Input
            type="number"
            value={verificationAltitude}
            onBlur={handleAltitudeChange}
            onChange={(e) => setVerificationAltitude(e.target.value ? parseInt(e.target.value, 10) : undefined)}
            className="input__text"
            placeholder="Enter altitude"
            fixedWidth="100%"
            suffix="feet"
          />
        </section>
      </div>
    </div>
  );
};

export default VerificationCard;
