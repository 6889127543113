/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, {
  ReactNode, useState, Children, useRef, useEffect
} from 'react';
import { Header } from '@flogistix/flo-ui';
import './styles.scss';

const validColors = ['blue', 'orange'];

interface TabProps {
  children: ReactNode;
  eventKey: string;
  title: ReactNode;
  isActive?: boolean;
  onClick?: (eventKey: string) => void;
  disabled?: boolean;
  headerSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fontStyle?: 'regular' | 'semi-bold';
  color?: 'blue' | 'orange' | 'black' | '';
}

export const Tab: React.FC<TabProps> = ({
  eventKey,
  title,
  isActive = false,
  onClick,
  disabled = false,
  headerSize = 'h1',
  fontStyle = 'semi-bold',
  color = ''
}) => {
  const handleClick = () => {
    if (!disabled) {
      if (onClick) onClick(eventKey);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!disabled && (event.key === ' ' || event.key === 'Enter')) {
      event.preventDefault();
      handleClick();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={(() => {
        let className = 'tab-item';
        if (isActive) {
          className += ` active ${color}`;
        } else if (color) {
          className += ` hover-${color}`;
        }
        if (disabled) {
          className += ' disabled';
        }
        return className;
      })()}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <Header
        headerSize={headerSize as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'}
        fontStyle={fontStyle as 'semi-bold' | 'regular'}
      >
        {title}
      </Header>
    </div>
  );
};

interface TabsProps {
  children: ReactNode;
  headerSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fontStyle?: 'regular' | 'semi-bold';
  defaultActiveKey: string;
  activeKey?: string;
  onSelect?: (eventKey: string) => void;
  className?: string;
  color?: 'blue' | 'orange' | 'black';
}

export const Tabs: React.FC<TabsProps> = ({
  children,
  className = 'tabs',
  defaultActiveKey,
  activeKey,
  headerSize = 'h1',
  onSelect,
  fontStyle = 'regular',
  color = 'blue'
}) => {
  const [currentActiveKey, setCurrentActiveKey] = useState(activeKey || defaultActiveKey);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const tabItems: ReactNode[] = Children.toArray(children);
  const horizontalLineRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLHeadingElement>(null);

  const normalizedColor = validColors.includes(color) ? color : '';

  const updateHorizontalLine = () => {
    const activeTab = containerRef.current?.querySelector('.tab-item.active');
    if (activeTab && horizontalLineRef.current) {
      const tabRect = activeTab.getBoundingClientRect();
      const headerRect = activeTab.parentElement!.getBoundingClientRect();
      horizontalLineRef.current.style.width = `${tabRect.width}px`;
      horizontalLineRef.current.style.left = `${tabRect.left - headerRect.left}px`;
    }
  };

  const handleSelect = (eventKey: string) => {
    if (currentActiveKey === eventKey) return;
    if (onSelect) {
      onSelect(eventKey);
    }
    setCurrentActiveKey(eventKey);
  };

  useEffect(() => {
    const isValidTab = tabItems.some(
      (child) => React.isValidElement(child) && child.props.eventKey === activeKey
    );
    if (activeKey && isValidTab) {
      setCurrentActiveKey(activeKey);
    }
  }, [activeKey, tabItems, onSelect]);

  useEffect(() => {
    if (isPageLoaded) {
      updateHorizontalLine();
    }
  }, [currentActiveKey, isPageLoaded, headerSize, fontStyle, activeKey]);

  useEffect(() => {
    if (document.fonts) {
      document.fonts.ready.then(() => {
        updateHorizontalLine();
        setIsPageLoaded(true);
      });
    } else {
      window.addEventListener('load', updateHorizontalLine);
      return () => {
        window.removeEventListener('load', updateHorizontalLine);
        setIsPageLoaded(true);
      };
    }
    return undefined;
  }, []);

  const renderedTabs = () => {
    const arrayOfChildren = Children.toArray(tabItems) as React.ReactElement<TabProps>[];

    const tabs = arrayOfChildren.map((child) => (
      <Tab
        key={child.props.eventKey}
        {...child.props}
        isActive={child.props.eventKey === currentActiveKey}
        headerSize={headerSize}
        onClick={handleSelect}
        fontStyle={fontStyle}
        color={normalizedColor}
      />
    ));
    return tabs;
  };

  const renderedContent = () => {
    const arrayOfChildren = Children.toArray(tabItems) as React.ReactElement<TabProps>[];
    const tabs = arrayOfChildren.map((child) => {
      if (child.props.eventKey === currentActiveKey) {
        return child.props.children;
      }
      return null;
    });
    return tabs;
  };

  return (
    <div className={`flo-tabs ${className}`} ref={containerRef}>
      <hr className={`flo-tabs--header-line ${headerSize}`} />
      <div className="flo-tabs--header">
        {renderedTabs()}
        <div ref={horizontalLineRef} className={`flo-tabs--horizontal-line ${normalizedColor}`} />
      </div>
      <div className="flo-tab-content">
        {renderedContent()}
      </div>
    </div>
  );
};
