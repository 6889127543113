/* eslint-disable import/no-cycle */
import { v4 as uuidv4 } from 'uuid';
import AirMethaneFile, { UploadFile } from './airmethane-file';
import Verification from './verification';
import Survey from './survey';
import { Leak } from './leak';
import { SiteCondition } from './site-conditions';
import MonitoredComponent from './monitored-component';
import {
  ComplianceType,
  ComponentType,
  InspectionFrequency,
  InspectionStatus,
  InspectionType,
  OperationalStatus
} from './enums';
import { Transaction } from '../dexie/db';
import { createTransaction, getLocalOrgByOrgId } from '../dexie/operations';
import { API_INSPECTION, urlPrefix } from '../shared/url';

interface SiteRemainingFields {
  partOne: string[]
  partTwo: string[]
}

interface InspectionRemainingFields {
  info: string[]
  verification: { dailyVerification: string[][], facilitySign: string }
  weather: string[]
  survey: string[][]
}

interface LeakRemainingFields {
  leak: string[][]
  repair: string[][]
}

interface RemainingFields {
  site: SiteRemainingFields
  inspection: InspectionRemainingFields
  leak: LeakRemainingFields
}

export interface Dictionary<T> {
  [Key: string]: T;
}

export interface Site {
  id: string;
  name: string;
}

export interface Org {
  id: string;
  name: string;
}

export interface ICredential {
  certificationExpiration: string;
  credentials: string;
  licenseNumber: string | number;
}

export interface Inspector {
  id: string;
  name: string;
  email: string;
  credentials: ICredential[];
}

export class Inspection {
  id?: string;

  createdBy?: string;

  createdAt?: Date;

  updatedBy?: string;

  updatedAt?: Date;

  inspectionReport?: AirMethaneFile | null;

  createdByName?: string;

  createdByEmail?: string;

  flogistixId?: string;

  inspectionDate?: Date;

  status?: InspectionStatus;

  inspectionType?: InspectionType | null;

  complianceType?: ComplianceType | null;

  state?: string | null;

  county?: string | null;

  latitude?: number | null;

  longitude?: number | null;

  inspectionFrequency?: InspectionFrequency | null;

  startTime?: string | null;

  endTime?: string | null;

  contactName?: string | null;

  contactPhone?: string | null;

  contactEmail?: string | null;

  contactApiNumber?: string | null;

  operationalStatus?: OperationalStatus | null;

  monitoringPlanDeviations?: string | null;

  locationSignFile?: AirMethaneFile | null;

  verifications?: Verification[] | null;

  siteConditions?: SiteCondition | null;

  surveys?: Survey[];

  leaks?: Leak[];

  monitoredComponents?: MonitoredComponent[] | null;

  files?: AirMethaneFile[] | null;

  org?: Org | null;

  site?: Site | null;

  version?: number;

  constructor({
    id,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    inspectionReport,
    createdByName,
    createdByEmail,
    flogistixId,
    inspectionDate,
    status,
    inspectionType,
    complianceType,
    state,
    county,
    latitude,
    longitude,
    inspectionFrequency,
    startTime,
    endTime,
    contactName,
    contactPhone,
    contactEmail,
    contactApiNumber,
    operationalStatus,
    monitoringPlanDeviations,
    locationSignFile,
    verifications,
    siteConditions,
    surveys,
    leaks,
    monitoredComponents,
    files,
    org,
    site,
    version
  }: Inspection) {
    if (id) {
      this.id = id;
      this.createdBy = createdBy;
      this.createdAt = createdAt;
      this.updatedBy = updatedBy;
      this.updatedAt = updatedAt;
      this.inspectionReport = inspectionReport;
      this.createdByName = createdByName;
      this.createdByEmail = createdByEmail;
      this.flogistixId = flogistixId;
      this.inspectionDate = inspectionDate;
      this.status = status;
      this.inspectionType = inspectionType ?? InspectionType.FACILITY;
      this.complianceType = complianceType;
      this.state = state;
      this.county = county;
      this.latitude = latitude;
      this.longitude = longitude;
      this.inspectionFrequency = inspectionFrequency;
      this.startTime = startTime;
      this.endTime = endTime;
      this.contactName = contactName;
      this.contactPhone = contactPhone;
      this.contactEmail = contactEmail;
      this.contactApiNumber = contactApiNumber;
      this.operationalStatus = operationalStatus ?? OperationalStatus.RUNNING;
      this.monitoringPlanDeviations = monitoringPlanDeviations;
      this.locationSignFile = locationSignFile;
      this.verifications = verifications;
      this.siteConditions = new SiteCondition(siteConditions ?? {});
      this.surveys = surveys;
      this.leaks = leaks;
      this.monitoredComponents = monitoredComponents;
      this.files = files;
      this.org = org;
      this.site = site;
      this.version = version ?? 0;
    } else {
      this.id = uuidv4();
    }
  }

  generateId() {
    this.id = uuidv4();
  }

  async createInspection(token: string) {
    this.status = InspectionStatus.DRAFT;
    this.complianceType = this.complianceType ?? ComplianceType.OOOO;
    const transaction = {
      queueId: `${this.flogistixId}`,
      request: {
        inspectionId: this.id,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: this as object,
        endpoint: `${API_INSPECTION}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  determineSubmissionReadiness(): boolean {
    const hasRequiredTopLevelFields = (
      this.inspectionDate !== undefined
      && this.inspectionType !== undefined
      && this.complianceType !== undefined
      && this.state !== undefined
      && this.county !== undefined
      && this.latitude !== undefined
      && this.longitude !== undefined
      && this.inspectionFrequency !== undefined
      && this.startTime !== undefined
      && this.endTime !== undefined
      && this.contactName !== undefined
      && this.contactPhone !== undefined
      && this.operationalStatus !== undefined
      && this.siteConditions?.skyConditions !== undefined
      && this.siteConditions?.ambientTemperature !== undefined
      && this.siteConditions?.windDirection !== undefined
      && this.siteConditions?.maxWindSpeed !== undefined
      && this.siteConditions?.humidityPercent !== undefined
      && this.siteConditions?.barometricPressure !== undefined
      && this.siteConditions?.viewingDistance !== undefined
    );

    if (!hasRequiredTopLevelFields) return false;

    const hasValidVerificationFields = this.verifications?.every(
      (verification) => verification.getSubmissionReadiness()
    ) ?? true;

    if (!hasValidVerificationFields) return false;

    const hasValidSurveyFields = this.surveys?.every((survey) => survey.getSubmissionReadiness()) ?? true;

    if (!hasValidSurveyFields) return false;

    const hasValidLeakFields = this.leaks?.every((leak) => leak.getSubmissionReadiness()) ?? true;

    if (!hasValidLeakFields) return false;

    const hasValidRepairFields = this.leaks ? this.leaks.every((leak) => {
      if (leak.leakRepairs && leak.leakRepairs.length > 0) {
        const allAreValid = leak.leakRepairs.every((repair) => (
          repair.date !== undefined
          && repair.file?.id !== undefined
        ));
        return allAreValid;
      }
      return true;
    }) : true;

    if (!hasValidRepairFields) return false;

    return true;
  }

  getSiteDetailCompletionPercentage() {
    const fieldsNeededForCompletion = 9;
    const fieldsPresent = [
      this.inspectionDate,
      this.site?.id,
      this.state,
      this.county,
      this.org?.id,
      this.contactName,
      this.contactPhone,
      this.latitude,
      this.longitude
    ].filter((field) => field);
    return (fieldsPresent.length / fieldsNeededForCompletion) * 100;
  }

  getSiteDetailCompletionStatus() {
    const percentage = this.getSiteDetailCompletionPercentage();
    return percentage === 100;
  }

  getInspectionDetailCompletionPercentage() {
    const detailsAndWeatherFields = 12;
    const detailsAndConditionsExisting = [
      this.inspectionType,
      this.operationalStatus,
      this.inspectionFrequency,
      this.startTime,
      this.endTime,
      this.locationSignFile?.id,
      this.siteConditions?.skyConditions,
      this.siteConditions?.ambientTemperature,
      this.siteConditions?.windDirection,
      this.siteConditions?.maxWindSpeed,
      this.siteConditions?.humidityPercent,
      this.siteConditions?.barometricPressure
    ].filter((field) => !!field);

    const topLevelCompletionPercentage = (detailsAndConditionsExisting.length / detailsAndWeatherFields) * 100;
    const surveyCompletionPercentage = this.surveys?.length ? (
      (this.surveys?.reduce((acc, survey) => acc + survey.getSurveyCompletionPercentage(), 0) ?? 0
      ) / this.surveys.length
    ) : 100;
    const verificationCompletionPercentage = this.verifications?.length ? (
      (
        this.verifications?.reduce((acc, v) => acc + v.getVerificationCompletionPercentage(), 0) ?? 0
      ) / this.verifications.length
    ) : 100;
    const totalCompletionPercentage = (
      topLevelCompletionPercentage + surveyCompletionPercentage + verificationCompletionPercentage
    ) / 3;
    return totalCompletionPercentage;
  }

  getInspectionDetailCompletionStatus() {
    const percentage = this.getInspectionDetailCompletionPercentage();
    return percentage === 100;
  }

  getLeakDetailCompletionPercentage() {
    if (this.leaks === undefined) return 0;
    if (this.leaks?.length) {
      const leakPercentageWithRepairs = (this.leaks.reduce((acc, leak) => (
        acc + leak.getLeakCompletionPercentage()
      ), 0) / this.leaks.length);
      return leakPercentageWithRepairs;
    }
    return 100;
  }

  getLeakDetailCompletionStatus() {
    const percentage = this.getLeakDetailCompletionPercentage();
    return percentage === 100;
  }

  getInspectionSummaryCompletionStatus() {
    const siteDetailCompletion = this.getSiteDetailCompletionStatus();
    const inspectionDetailCompletion = this.getInspectionDetailCompletionStatus();
    const leakDetailCompletion = this.getLeakDetailCompletionStatus();
    return siteDetailCompletion && inspectionDetailCompletion && leakDetailCompletion;
  }

  getFinalSubmissionCompletionStatus() {
    const isReady = this.getInspectionSummaryCompletionStatus();
    return isReady;
  }

  getSiteDetailsFields() {
    const siteDetailsFields: SiteRemainingFields = {
      partOne: [
        this?.inspectionType ? null : 'Inspection type',
        this?.inspectionDate ? null : 'Inspection date',
        this?.org ? null : 'Customer',
        this?.site ? null : 'Site',
        this?.state ? null : 'State',
        this?.county ? null : 'County'
      ].filter((field): field is string => field !== null),
      partTwo: [
        this?.contactName ? null : 'Contact name',
        this?.contactPhone ? null : 'Contact phone',
        this?.latitude ? null : 'Latitude',
        this?.longitude ? null : 'Longitude'
      ].filter((field): field is string => field !== null)
    };
    return siteDetailsFields;
  }

  getVerificationFields() {
    const verificationFields:{ dailyVerification: string[][], facilitySign: string } = {
      dailyVerification: [],
      facilitySign: ''
    };
    this?.verifications?.forEach((verification, index) => {
      verificationFields.dailyVerification[index] = verification.getRemainingFields();
    });
    if (!this.locationSignFile?.id || this.locationSignFile.id === '') {
      verificationFields.facilitySign = 'Facility Sign';
    }
    return verificationFields;
  }

  getSurveyFields() {
    const surveyFields: string[][] = [];
    this.surveys?.forEach((survey, index) => {
      surveyFields[index] = survey.getRemainingFields();
    });
    return surveyFields;
  }

  getInspectionDetailsFields() {
    const inspectionDetailsFields: InspectionRemainingFields = {
      info: [
        this?.complianceType ? null : 'Compliance type',
        this?.operationalStatus ? null : 'Operational status',
        this?.inspectionFrequency ? null : 'Inspection frequency',
        this?.startTime ? null : 'Start time',
        this?.endTime ? null : 'End time'
      ].filter((field): field is string => field !== null),
      verification: this.getVerificationFields(),
      weather: new SiteCondition(this.siteConditions ?? {}).getRemainingFields() || [],
      survey: this.getSurveyFields()
    };
    return inspectionDetailsFields;
  }

  getLeakFields() {
    const leakFields: string[][] = [];
    this.leaks?.forEach((leak, index) => {
      leakFields[index] = leak.getRemainingFields();
    });
    return leakFields;
  }

  getLeakRepairFields() {
    const repairFields: string[][] = [];
    this.leaks?.forEach((leak, index) => {
      leak.leakRepairs?.forEach((repair, repairIndex) => {
        repairFields[index + repairIndex] = repair.getRemainingFields();
      });
    });
    return repairFields;
  }

  getLeakDetailsFields() {
    return {
      leak: this.getLeakFields(),
      repair: this.getLeakRepairFields()
    };
  }

  getRemainingFields() {
    const remainingFields: RemainingFields = {
      site: this.getSiteDetailsFields(),
      inspection: this.getInspectionDetailsFields(),
      leak: this.getLeakDetailsFields()
    };
    return remainingFields;
  }

  getSiteDetailsRemainingAmount() {
    const remainingFields = this.getSiteDetailsFields();
    return remainingFields.partOne.length + remainingFields.partTwo.length;
  }

  getInspectionDetailsRemainingAmount() {
    const remainingFields = this.getInspectionDetailsFields();
    return remainingFields.info.length
    + remainingFields.weather.length
    + remainingFields.survey.reduce((acc, survey) => acc + survey.length, 0)
    + remainingFields.verification.dailyVerification.reduce((acc, verification) => acc
      + verification.length, 0)
    + (remainingFields.verification.facilitySign === '' ? 0 : 1);
  }

  getLeakDetailsRemainingAmount() {
    const remainingFields = this.getLeakDetailsFields();
    return remainingFields.leak.reduce((acc, leak) => acc + leak.length, 0)
    + remainingFields.repair.reduce((acc, repair) => acc + repair.length, 0);
  }

  getTotalRemainingAmount() {
    return this.getSiteDetailsRemainingAmount()
    + this.getInspectionDetailsRemainingAmount() + this.getLeakDetailsRemainingAmount();
  }

  async updateFlogistixId(flogistixId: string) {
    this.flogistixId = flogistixId;
  }

  async removeCreatedByName(token: string) {
    this.createdByName = undefined;
    if (this.flogistixId) await this.removeField(token, 'createdByName');
  }

  async removeCreatedByEmail(token: string) {
    this.createdByEmail = undefined;
    if (this.flogistixId) await this.removeField(token, 'createdByEmail');
  }

  async removeFlogistixId(token: string) {
    this.flogistixId = undefined;
    if (this.flogistixId) await this.removeField(token, 'flogistixId');
  }

  async removeInspectionDate(token: string) {
    this.inspectionDate = undefined;
    if (this.flogistixId) await this.removeField(token, 'inspectionDate');
  }

  async removeStatus(token: string) {
    this.status = undefined;
    if (this.flogistixId) await this.removeField(token, 'status');
  }

  async removeInspectionType(token: string) {
    this.inspectionType = undefined;
    if (this.flogistixId) await this.removeField(token, 'inspectionType');
  }

  async removeComplianceType(token: string) {
    this.complianceType = undefined;
    if (this.flogistixId) await this.removeField(token, 'complianceType');
  }

  async removeState(token: string) {
    this.state = undefined;
    if (this.flogistixId) await this.removeField(token, 'state');
  }

  async removeCounty(token: string) {
    this.county = undefined;
    if (this.flogistixId) await this.removeField(token, 'county');
  }

  async removeLatitude(token: string) {
    this.latitude = undefined;
    if (this.flogistixId) await this.removeField(token, 'latitude');
  }

  async removeLongitude(token: string) {
    this.longitude = undefined;
    if (this.flogistixId) await this.removeField(token, 'longitude');
  }

  async removeInspectionFrequency(token: string) {
    this.inspectionFrequency = undefined;
    if (this.flogistixId) await this.removeField(token, 'inspectionFrequency');
  }

  async removeStartTime(token: string) {
    this.startTime = undefined;
    if (this.flogistixId) await this.removeField(token, 'startTime');
  }

  async removeEndTime(token: string) {
    this.endTime = undefined;
    if (this.flogistixId) await this.removeField(token, 'endTime');
  }

  async removeContactName(token: string) {
    this.contactName = undefined;
    if (this.flogistixId) await this.removeField(token, 'contactName');
  }

  async removeContactPhone(token: string) {
    this.contactPhone = undefined;
    if (this.flogistixId) await this.removeField(token, 'contactPhone');
  }

  async removeContactEmail(token: string) {
    this.contactEmail = undefined;
    if (this.flogistixId) await this.removeField(token, 'contactEmail');
  }

  async removeContactApiNumber(token: string) {
    this.contactApiNumber = undefined;
    if (this.flogistixId) await this.removeField(token, 'contactApiNumber');
  }

  async removeOperationalStatus(token: string) {
    this.operationalStatus = undefined;
    if (this.flogistixId) await this.removeField(token, 'operationalStatus');
  }

  async removeMonitoringPlanDeviations(token: string) {
    this.monitoringPlanDeviations = undefined;
    if (this.flogistixId) await this.removeField(token, 'monitoringPlanDeviations');
  }

  async getOrgNetSuiteId() {
    const localOrgObject = await getLocalOrgByOrgId(this.org!.id! ?? '');
    if (localOrgObject) return localOrgObject.netsuiteId;
    return null;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  async removeField(token: string, field: any) {
    const transaction = {
      queueId: `${this.flogistixId}`,
      request: {
        inspectionId: this.id,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: {},
        endpoint: `${API_INSPECTION}/${this.id}/${field}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async updateInspectionType(token: string, inspectionType: InspectionType) {
    this.inspectionType = inspectionType;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/inspection-type/${inspectionType}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateInspectionDate(token: string, inspectionDate: string) {
    this.inspectionDate = new Date(inspectionDate);
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/inspection-date/${inspectionDate}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateInspectionState(token: string, state: string) {
    this.state = state;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/state/${state}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
    return this;
  }

  async updateInspectionCounty(token: string, county: string) {
    this.county = county;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/county/${county}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateComplianceType(token: string, complianceType: ComplianceType) {
    this.complianceType = complianceType;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/compliance-type/${complianceType}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateLatitude(token: string, latitude: number) {
    this.latitude = latitude;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/latitude/${latitude}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateLongitude(token: string, longitude: number) {
    this.longitude = longitude;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/longitude/${longitude}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateInspectionFrequency(token: string, frequency: InspectionFrequency) {
    this.inspectionFrequency = frequency;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/inspection-frequency/${frequency}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateStartTime(token: string, startTime: string) {
    this.startTime = startTime;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/start-time/${startTime}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateEndTime(token: string, endTime: string) {
    this.endTime = endTime;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/end-time/${endTime}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateContactName(token: string, contactName: string) {
    this.contactName = contactName;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/contact-name/${contactName}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateContactPhone(token: string, contactPhone: string) {
    this.contactPhone = contactPhone;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/contact-phone/${contactPhone}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateContactEmail(token: string, contactEmail: string) {
    this.contactEmail = contactEmail;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/contact-email/${contactEmail}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateContactApiNumber(token: string, contactApiNumber: string) {
    this.contactApiNumber = contactApiNumber;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/contact-api-number/${contactApiNumber}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateOperationalStatus(token: string, operationalStatus: OperationalStatus) {
    this.operationalStatus = operationalStatus;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/operational-status/${operationalStatus}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async addSurvey(token: string, survey: Survey) {
    /* eslint no-param-reassign: "off" */
    if (survey.surveyId == null) { survey.surveyId = uuidv4(); }
    if (!this.surveys) this.surveys = [];
    this.surveys?.push(survey);
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->survey`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {
            body: survey,
            inspectionId: this.id
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${this.id}/addSurvey`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async removeSurvey(token: string, survey: Survey) {
    this.surveys = this.surveys?.filter((s) => s.surveyId !== survey.surveyId);
    if (this.flogistixId) {
      delete survey.pendingFiles;
      const transaction = {
        queueId: `${this.flogistixId}->survey`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: survey as object,
          endpoint: `${API_INSPECTION}/${this.id}/removeSurvey`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  initializeLeaks() {
    this.leaks = [];
  }

  async addLeak(token: string, leak: Leak) {
    /* eslint no-param-reassign: "off" */
    if (this.leaks === undefined) this.leaks = [];
    if (leak.tagNumber == null) { leak.tagNumber = uuidv4(); }
    if (!leak.componentType) { leak.componentType = ComponentType.CLOSED_VENT_SYSTEM; }
    this.leaks?.push(leak);
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->leaks`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: leak as object,
          fileUpload: !!leak.pendingFiles,
          endpoint: `${API_INSPECTION}/${this.id}/addLeak`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async removeLeak(token: string, leak: Leak) {
    this.leaks = this.leaks?.filter((l) => l.tagNumber !== leak.tagNumber);
    if (this.flogistixId) {
      delete leak.pendingFiles;
      const transaction = {
        queueId: `${this.flogistixId}->leaks`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: leak as object,
          endpoint: `${API_INSPECTION}/${this.id}/removeLeak`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async addVerification(token: string, verification: Verification) {
    if (!this.verifications) this.verifications = [];
    if (verification.verificationId === null) verification.verificationId = uuidv4();
    this.verifications?.push(verification);
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->verification`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: verification.pendingFiles?.length ? {
            body: verification,
            inspectionId: this.id
          } : verification as object,
          fileUpload: !!verification.pendingFiles,
          endpoint: `${API_INSPECTION}/${this.id}/addVerification`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async removeVerification(token: string, verification: Verification, fileVersion: string) {
    this.verifications = this.verifications?.filter((v) => v.verificationId !== verification.verificationId);

    if (verification.file && verification.file.id && fileVersion) {
      const fileTransaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          endpoint: `https://${urlPrefix}files.api.axil.ai/files/${verification?.file?.id}`,
          payload: {},
          version: fileVersion,
          fileUpload: false
        }
      } as Transaction;

      await createTransaction(fileTransaction);
    }

    if (this.flogistixId) {
      delete verification.pendingFiles;
      const transaction = {
        queueId: `${this.flogistixId}->verification`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: verification as object,
          endpoint: `${API_INSPECTION}/${this.id}/removeVerification`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateSiteConditions(token: string, siteConditions: Partial<SiteCondition>) {
    this.siteConditions = siteConditions as SiteCondition;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: siteConditions as object,
          endpoint: `${API_INSPECTION}/${this.id}/site-conditions`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async addMonitoredComponent(token: string, component: MonitoredComponent) {
    if (this.monitoredComponents === undefined) this.monitoredComponents = [];
    if (component.monitoredComponentId === null) component.monitoredComponentId = uuidv4();
    this.monitoredComponents?.push(component);
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->monitored-component`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: component as object,
          endpoint: `${API_INSPECTION}/${this.id}/addMonitoredComponent`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async removeMonitoredComponent(token: string, component: MonitoredComponent) {
    this.monitoredComponents = this.monitoredComponents?.filter(
      (c) => c.monitoredComponentId !== component.monitoredComponentId
    );
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->monitored-component`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: component as object,
          endpoint: `${API_INSPECTION}/${this.id}/removeMonitoredComponent`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateMonitoredComponent(token: string, component: MonitoredComponent) {
    this.monitoredComponents = this.monitoredComponents?.map((c) => {
      if (c.monitoredComponentId === component.monitoredComponentId) return component;
      return c;
    });
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->monitored-component`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: component as object,
          endpoint: `${API_INSPECTION}/${this.id}/patchMonitoredComponent`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateMonitoringPlanDeviations(token: string, deviations: string) {
    this.monitoringPlanDeviations = deviations;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/monitoring-plan-deviations/${deviations}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async submitInspection(token: string) {
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}/submit`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async reopenInspection(token: string) {
    const transaction = {
      queueId: `${this.flogistixId}`,
      request: {
        inspectionId: this.id,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: {},
        endpoint: `${API_INSPECTION}/${this.id}/reopen`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async updateOrg(token: string, org: Org) {
    this.org = org;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: org as object,
          endpoint: `${API_INSPECTION}/${this.id}/org`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateSite(token: string, site: Site) {
    this.site = site;
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: site as object,
          endpoint: `${API_INSPECTION}/${this.id}/site`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async updateLocationSignFile(token: string, locationSignFile: UploadFile) {
    this.locationSignFile = new AirMethaneFile(locationSignFile.id);
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->location-sign-file`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          payload: {
            pendingFiles: [locationSignFile],
            inspectionId: this.id
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${this.id}/location-sign-file`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async attachLocationSignFile(token : string, locationSignFile : UploadFile) {
    this.locationSignFile = new AirMethaneFile(locationSignFile.id);

    const uploadedFiles = this.locationSignFile ? [this.locationSignFile] : null;

    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}->location-sign-file`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          payload: {
            pendingFiles: [],
            uploadedFiles,
            inspectionId: this.id
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${this.id}/location-sign-file`
        }
      } as Transaction;

      await createTransaction(transaction, this);
    }
  }

  async removeLocationSignFile(token: string, fileVersion: number, id: string | undefined) {
    this.locationSignFile = undefined;
    const fileTransaction = {
      queueId: `${this.flogistixId}->location-sign-file`,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${id}`,
        payload: {},
        version: `${fileVersion}`
      }
    } as Transaction;

    await createTransaction(fileTransaction, this);

    this.detachLocationSignFile(token);
  }

  async detachLocationSignFile(token: string) {
    this.locationSignFile = undefined;
    if (this.flogistixId) {
      const signTransaction = {
        queueId: `${this.flogistixId}->location-sign-file`,
        request: {
          inspectionId: this.id,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          payload: { id: '' },
          endpoint: `${API_INSPECTION}/${this.id}/location-sign-file`
        }
      } as Transaction;
      await createTransaction(signTransaction);
    }
  }

  async deleteInspection(token: string) {
    if (this.flogistixId) {
      const transaction = {
        queueId: `${this.flogistixId}`,
        request: {
          inspectionId: this.id,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          },
          payload: {},
          endpoint: `${API_INSPECTION}/${this.id}`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async addFiles(token: string, flogistixId: string, inspectionId: string, newFiles: UploadFile[]) {
    const transaction = {
      queueId: `${flogistixId}->inspections->${this.id}`,
      request: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: {
          pendingFiles: newFiles,
          uploadedFiles: [],
          inspectionId
        },
        endpoint: `https://${urlPrefix}inspections.api.axil.ai/inspections/${inspectionId}`,
        fileUpload: true
      }
    } as unknown as Transaction;

    await createTransaction(transaction);
  }

  async removeFile(token: string, flogistixId: string, fileIdToRemove: string) {
    const transaction = {
      queueId: `${flogistixId}->files->${this.id}`,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: {},
        version: 0,
        fileUpload: false,
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${fileIdToRemove}`
      }
    } as Transaction;
    await createTransaction(transaction);
  }
}
