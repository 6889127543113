import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@flogistix/flo-ui';

import { InspectionContext } from '../../../../context/InspectionContext';
import SubmitConfirmationModal from '../../../../components/Modals/SubmitConfirmationModal/SubmitConfirmationModal';
import './FinalSubmission.scss';

const FinalSubmission = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { inspection, token } = useContext(InspectionContext);
  const isReady = inspection?.determineSubmissionReadiness();

  const handleSubmission = () => {
    inspection!.submitInspection(token).then(() => {
      navigate('/');
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="final-submission">
      <h1 className="submit-title">Before you submit</h1>
      <p className="submit-subtext">Make sure you have everything ready</p>
      <br />
      <br />
      <h4 className="confirm-title">I understand...</h4>
      <p className="confirm-text">
        I understand that once I submit this form, I will not be
        able to make any changes or adjustments to the content or
        inspection created. I also confirm that all the documents
        and images included in this form meet the criteria for
        inspection established by the relevant state and federal
        regulations. I am aware that I will be held accountable if
        the documents and images fail to meet these standards.
        Therefore, I take full responsibility for the accuracy and
        completeness of the submission I am providing.
      </p>
      <br />
      <Button
        variation="blue"
        type="button"
        disabled={!isReady}
        onClick={handleOpenModal}
        fixedWidth="220px"
      >
        Submit inspection
      </Button>
      <SubmitConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSubmission}
      />
    </div>
  );
};

export default FinalSubmission;
