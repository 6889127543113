import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoAddOutline } from 'react-icons/io5';
import { InspectionContext } from '../../../../context/InspectionContext';
import MonitoredComponent from '../../../../classes/monitored-component';
import MonitoredComponentCard from './MonitoredComponentCard';

const MonitoredComponents = () => {
  const { inspection, token } = useContext(InspectionContext);
  const [monitoredComponents, setMonitoredComponents] = useState(inspection!.monitoredComponents
      || [new MonitoredComponent({ monitoredComponentId: uuidv4() })]);

  useState(() => {
    if (monitoredComponents.length === 0) {
      setMonitoredComponents([new MonitoredComponent({ monitoredComponentId: uuidv4() })]);
    }
  });

  const createMonitoredComponent = () => {
    const newComponent = new MonitoredComponent({ monitoredComponentId: uuidv4() });
    setMonitoredComponents([...monitoredComponents, newComponent]);
  };

  const removeMonitoredComponent = (component: MonitoredComponent) => {
    setMonitoredComponents(
      (monitoredComponents ?? []).filter((c) => c.monitoredComponentId !== component.monitoredComponentId)
    );
    if (component.componentType) inspection!.removeMonitoredComponent(token, component);
  };

  return (
    <div>
      <div className="section-heading">
        <h1 className="page-details__header">Monitoring details</h1>
        <h4 className="part">Part 2 of 2</h4>
        <button
          className="add-new-item-button"
          type="button"
          onClick={createMonitoredComponent}
        >
          <IoAddOutline />
          Add new component
        </button>
      </div>
      {
        monitoredComponents.map((c, i) => (
          <MonitoredComponentCard
            key={c.monitoredComponentId}
            component={c}
            componentIndex={i}
            removeMonitoredComponent={(newComponent: MonitoredComponent) => { removeMonitoredComponent(newComponent); }}
          />
        ))
      }
      <div className="section-footer">
        <button
          className="add-new-item-button-bottom"
          type="button"
          onClick={createMonitoredComponent}
        >
          <IoAddOutline />
          Add new component
        </button>
      </div>
    </div>
  );
};

export default MonitoredComponents;
