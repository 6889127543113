/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Button } from '@flogistix/flo-ui';

import './SubmitConfirmationModal.scss';

interface SubmitConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const SubmitConfirmationModal: React.FC<SubmitConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm
}) => {
  if (!isOpen) return null;

  return (
    <div className="submission-modal-overlay">
      <div className="submission-modal-content">
        <h2 className="submission-modal-header">Are you sure?</h2>
        <p className="submission-modal-subheader">
          Submitting this inspection will finalize all information you have entered.
        </p>
        <div className="submission-modal-actions">
          <Button
            variation="black-outline"
            className="submission-width-no-padding-left"
            onClick={onClose}
            fixedWidth="173px"
          >
            No, don't submit
          </Button>
          <Button
            variation="blue"
            className="submission-width-no-padding"
            onClick={onConfirm}
            fixedWidth="173px"
          >
            Yes, submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubmitConfirmationModal;
