import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoAddOutline } from 'react-icons/io5';

import { InspectionContext } from '../../../../../context/InspectionContext';
import Verification from '../../../../../classes/verification';
import VerificationCard from './VerificationCard';
import { fetchFileUrl, fetchFilesForInspection } from '../../../../../services/airmethaneApi';
import AirMethaneFile, { PreviewFile } from '../../../../../classes/airmethane-file';
import BaseDropzone from '../../../../../components/FileDropzone/BaseDropzone/BaseDropzone';

const Verifications = () => {
  const {
    inspection, token, setCompletedSectionsOnChange, fileOptions, setFileOptions, hasFileId
  } = useContext(InspectionContext);
  const [verifications, setVerifications] = useState(inspection?.verifications || []);
  const [locationSignFile, setLocationSignFile] = useState<PreviewFile | null>(inspection?.locationSignFile || null);

  const addVerification = () => {
    const newVerification = new Verification({
      verificationId: uuidv4()
    });
    inspection!.addVerification(token, newVerification).then(() => {
      setVerifications([...verifications, newVerification]);
      setCompletedSectionsOnChange();
    });
  };

  const handleFacilitySignChange = (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    const files = event.type === 'drop'
      ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files
      : (event as React.ChangeEvent<HTMLInputElement>).target.files;
    const file = files?.[0];
    if (file) {
      const fileToUpload = { id: uuidv4(), file, displayName: file.name };
      const restFile = { id: fileToUpload.id, fileType: file.type, fileName: file.name };
      inspection!.updateLocationSignFile(token, fileToUpload).then(() => {
        setLocationSignFile(restFile as PreviewFile);
        setCompletedSectionsOnChange();
      });
    } else {
      console.log('No file selected');
    }
  };

  const handleFacilityAttachment = async (fileId: string | number) => {
    const selectedFile = fileOptions.find((file: { value: string | number; }) => file.value === fileId);

    if (selectedFile) {
      try {
        const newFile = new AirMethaneFile(selectedFile.value as string, selectedFile.label);

        await inspection!.attachLocationSignFile(token, {
          id: newFile.id as string,
          file: newFile as unknown as File,
          displayName: selectedFile.label
        });

        setLocationSignFile({
          id: selectedFile.value as string,
          previewUrl: selectedFile.label,
          fileName: selectedFile.label,
          fileType: ''
        } as PreviewFile);

        setCompletedSectionsOnChange();
      } catch (error) {
        console.error('Error updating location sign file:', error);
      }
    }
  };

  const handleRemoveFacilitySign = (fileId?: string) => {
    const idToRemove = fileId || inspection?.locationSignFile?.id;
    inspection!.removeLocationSignFile(token, 0, idToRemove).then(() => {
      setLocationSignFile(null);
      setCompletedSectionsOnChange();
    });
  };

  const detachRemoveFacilitySign = () => {
    inspection!.detachLocationSignFile(token).then(() => {
      setLocationSignFile(null);
      setCompletedSectionsOnChange();
    });
  };

  const deleteVerification = async (verification: Verification) => {
    if (verification?.file?.id) {
      const response = await fetchFileUrl(verification?.file?.id, token);
      const fileVersion = response?.version;
      inspection!.removeVerification(token, verification, fileVersion).then(() => {
        setVerifications(
          inspection?.verifications?.filter((v) => v.verificationId !== verification.verificationId)
          ?? []
        );
      });
    } else {
      inspection!.removeVerification(token, verification, '').then(() => {
        setVerifications(
          inspection?.verifications?.filter((v) => v.verificationId !== verification.verificationId)
          ?? []
        );
      });
    }
    setCompletedSectionsOnChange();
  };

  useEffect(() => {
    if (!inspection!.verifications || inspection!.verifications.length === 0) {
      const newVerification = new Verification({
        verificationId: uuidv4()
      });
      inspection!.addVerification(token, newVerification).then(() => {
        setVerifications([...verifications, newVerification]);
        setCompletedSectionsOnChange();
      });
    }
  }, []);

  useEffect(() => {
    const fetchFileOptions = async () => {
      if (inspection?.id && token && fileOptions.length === 0) {
        try {
          const files = await fetchFilesForInspection(inspection.id, token);
          const options = files.map((file: { id: string, name: string }) => ({
            label: file.name,
            value: file.id
          }));
          setFileOptions(options);
        } catch (error) {
          console.error('Error fetching inspection files:', error);
        }
      }
    };

    fetchFileOptions();
  }, [inspection?.id, token, fileOptions.length, setFileOptions]);

  return (
    <div>
      <h1 className="page-details__header">Inspection details</h1>
      <h4 className="part">Part 2 of 4</h4>
      {
        verifications?.map((verification, i) => (
          <VerificationCard
            key={verification.verificationId}
            index={i}
            verification={verification}
            deleteVerification={() => deleteVerification(verification)}
          />
        ))
      }
      {
        verifications.length < 2 && (
          <>
            <IoAddOutline className="plus-icon" size={16} />
            <button
              type="button"
              onClick={addVerification}
              className="add-button"
            >
              Add new verification
            </button>
          </>
        )
      }
      <br />
      <br />
      <div className="card">
        <section>
          <p className="card-header">Facility sign</p>
          <p className="sub-label">Files should be in .jpg, .png, .heic, .mp4, or .mov</p>
          <BaseDropzone
            onFileChange={handleFacilitySignChange}
            acceptedTypes=".png, .jpg, .jpeg, .heic, .mp4, .mov, video, video/mp4"
            removeHandler={handleRemoveFacilitySign}
            detachHandler={detachRemoveFacilitySign}
            showRemoveButton={!!locationSignFile?.id}
            fileId={locationSignFile?.id}
            selectOptions={fileOptions}
            onSelectChange={handleFacilityAttachment}
            deleteFileDisabled={hasFileId(locationSignFile?.id as string)}
          />
        </section>
      </div>
      <span className="spacer" />
    </div>
  );
};

export default Verifications;
