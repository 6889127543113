import { useContext, useState } from 'react';
import { Input, Dropdown } from '@flogistix/flo-ui';

import { InspectionContext } from '../../../../context/InspectionContext';
import { SkyCondition, WindDirection } from '../../../../classes/enums';
import { SiteCondition } from '../../../../classes/site-conditions';
import './Weather.scss';

const Weather = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext) || {};
  const [searchWind, setSearchWind] = useState('');
  const [searchSky, setSearchSky] = useState('');

  const [maxWindSpeed, setMaxWindSpeed] = useState(
    inspection?.siteConditions?.maxWindSpeed ?? undefined
  );
  const [windDirection, setWindDirection] = useState<WindDirection>(
    inspection?.siteConditions?.windDirection ?? WindDirection.NORTH
  );
  const [temperature, setTemperature] = useState(
    inspection?.siteConditions?.ambientTemperature ?? undefined
  );
  const [barometricPressure, setBarometricPressure] = useState(
    inspection?.siteConditions?.barometricPressure ?? undefined
  );
  const [humidity, setHumidity] = useState(
    inspection?.siteConditions?.humidityPercent ?? undefined
  );
  const [skyConditions, setSkyConditions] = useState<SkyCondition>(
    inspection?.siteConditions?.skyConditions ?? SkyCondition.CLEAR
  );
  const [viewingDistance, setViewingDistance] = useState(
    inspection?.siteConditions?.viewingDistance ?? undefined
  );

  const updateSiteConditions = (updates: Partial<SiteCondition>) => {
    if (inspection && token) {
      const currentConditions: Partial<SiteCondition> = {
        ...inspection.siteConditions,
        maxWindSpeed,
        windDirection,
        ambientTemperature: temperature,
        barometricPressure,
        humidityPercent: humidity,
        skyConditions,
        viewingDistance,
        ...updates
      };
      inspection.updateSiteConditions(token, currentConditions);
      setCompletedSectionsOnChange();
    }
  };

  const handleInputChange = (
    setter: React.Dispatch<React.SetStateAction<number | undefined>>,
    field: keyof SiteCondition
  ) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value ? parseInt(e.target.value, 10) : undefined;
    setter(newValue);
    updateSiteConditions({ [field]: newValue });
    setCompletedSectionsOnChange();
  };

  const handleWindDirectionChange = (selectedOption: { value: string; label: string }) => {
    const newWindDirection = selectedOption.value as WindDirection;
    setWindDirection(newWindDirection);
    updateSiteConditions({ windDirection: newWindDirection });
    setCompletedSectionsOnChange();
  };

  const handleSkyConditionsChange = (selectedOption: { value: string; label: string }) => {
    const newSkyConditions = selectedOption.value as SkyCondition;
    setSkyConditions(newSkyConditions);
    updateSiteConditions({ skyConditions: newSkyConditions });
    setCompletedSectionsOnChange();
  };

  const windDirectionOptions = Object.values(WindDirection).map((direction) => ({
    value: direction,
    label: direction
  }));

  const skyConditionOptions = Object.values(SkyCondition).map((condition) => ({
    value: condition,
    label: condition
  }));

  return (
    <>
      <h1 className="page-details__header">Inspection details</h1>
      <h4 className="part">Part 3 of 4 - Weather conditions</h4>
      <label className="label">Max wind speed</label>
      <Input
        type="number"
        value={maxWindSpeed}
        onChange={(e) => setMaxWindSpeed(e.target.value ? parseFloat(e.target.value) : undefined)}
        onBlur={handleInputChange(setMaxWindSpeed, 'maxWindSpeed')}
        className="input__text"
        placeholder="Enter max wind speed"
        fixedWidth="100%"
        suffix="miles per hour (mph)"
      />
      <label className="label">Wind direction</label>
      <Dropdown
        id="wind-direction"
        value={windDirection}
        onSelect={(selectedOption) => handleWindDirectionChange(selectedOption as { value: string, label: string })}
        className="input__select"
        placeholder="Select Direction"
        options={windDirectionOptions}
        searchValue={searchWind}
        onSearchChange={(value) => setSearchWind(value)}
        fixedWidth="100%"
      />
      <label className="label">Temperature</label>
      <Input
        type="number"
        value={temperature}
        onChange={(e) => setTemperature(e.target.value ? parseFloat(e.target.value) : undefined)}
        onBlur={handleInputChange(setTemperature, 'ambientTemperature')}
        className="input__text"
        placeholder="Enter temperature"
        fixedWidth="100%"
        suffix="° Fahrenheit (F)"
      />
      <label className="label">Barometric pressure</label>
      <Input
        type="number"
        value={barometricPressure}
        onChange={(e) => setBarometricPressure(e.target.value ? parseFloat(e.target.value) : undefined)}
        onBlur={handleInputChange(setBarometricPressure, 'barometricPressure')}
        className="input__text"
        placeholder="Enter barometric pressure"
        fixedWidth="100%"
        suffix="Mercury (Hg)"
      />
      <label className="label">Humidity</label>
      <Input
        type="number"
        value={humidity}
        onChange={(e) => setHumidity(e.target.value ? parseFloat(e.target.value) : undefined)}
        onBlur={handleInputChange(setHumidity, 'humidityPercent')}
        className="input__text"
        placeholder="Enter humidity"
        fixedWidth="100%"
        suffix="%"
      />
      <label className="label">Sky conditions</label>
      <Dropdown
        id="sky-conditions"
        value={skyConditions}
        onSelect={(selectedOption) => handleSkyConditionsChange(selectedOption as { value: string, label: string })}
        className="input__select"
        placeholder="Select Sky Condition"
        searchValue={searchSky}
        onSearchChange={(value) => setSearchSky(value)}
        options={skyConditionOptions}
        fixedWidth="100%"
      />
      <label className="label">Viewing distance</label>
      <Input
        type="number"
        value={viewingDistance}
        onBlur={handleInputChange(setViewingDistance, 'viewingDistance')}
        onChange={(e) => setViewingDistance(e.target.value ? parseFloat(e.target.value) : undefined)}
        className="input__text"
        placeholder="Enter viewing distance"
        fixedWidth="100%"
        suffix="Feet (ft)"
      />
      <span className="spacer" />
    </>
  );
};

export default Weather;
